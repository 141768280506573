<template>
  <v-container>
    <v-autocomplete v-model="userSettings.uiLanguage" :items="languages" :label="$t('settings-page.ui-language')"
                    item-text="lang" item-value="val" return-object v-on:change="collectArgs()">
    </v-autocomplete>
    <v-btn v-on:click="$emit('update-settings',args)" color="primary">{{$t('buttons.save')}}</v-btn>
  </v-container>
</template>

<script>
  export default {
    name: "GlobalSettingsTab",
    props: ['userSettings'],
    data() {
      return {
        args: [],
        languages: [
          {id: 1, val: 'en', lang: this.$t('locales.en')},
          {id: 2, val: 'uk', lang: this.$t('locales.ua')},
          {id: 3, val: 'de', lang: this.$t('locales.de')},
          {id: 3, val: 'fr', lang: this.$t('locales.fr')},
          {id: 5, val: 'es', lang: this.$t('locales.es')},
          {id: 6, val: 'cn', lang: this.$t('locales.zh-cn')}
        ]
      }
    },
    methods: {
      collectArgs() {
        this.args = [this.userSettings.uiLanguage.val, 'global']
      }
    }
  }
</script>

<style scoped>

</style>
