<template>
  <v-container>
    <v-switch inset v-model="userSettings.minOrderEnabled"
              :label="userSettings.minOrderEnabled? $t('settings-page.min-order-enabled') :$t('settings-page.min-order-disabled')"
              v-on:change="collectArgs()">
    </v-switch>
    <v-autocomplete
      v-model="userSettings.defaultOrderType" :items="orderTypes" :label="$t('settings-page.default-order-type')"
      item-text="orderTypeName" item-value="orderTypeName" v-on:change="collectArgs()">
    </v-autocomplete>
    <v-btn v-on:click="$emit('update-settings',args)" color="primary">{{$t('buttons.save')}}</v-btn>
  </v-container>
</template>

<script>
  export default {
    name: "OrderSettingsTab",
    props: ['userSettings'],
    data() {
      return {
        args: [],
        orderTypes: []
      }
    },
    methods: {
      collectArgs() {
        this.args = [this.userSettings, 'order']
        console.log("order setting: ",this.userSettings)
      }
    },
    created() {
      this.getActiveOrderTypes()
    },
  }
</script>

<style scoped>

</style>
