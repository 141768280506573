<template>
  <v-container>
    <v-switch inset v-model="userSettings.showWeekends"
              :label="userSettings.showWeekends? $t('settings-page.weekends-enabled') :$t('settings-page.weekends-disabled')"
              v-on:change="collectArgs()">
    </v-switch>
    <v-autocomplete deletable-chips chips v-model="selectedDays" :items="daysOfWeek"
                    :label="$t('settings-page.days-of-week')"
                    item-text="day" item-value="val" multiple v-on:change="collectArgs()">
    </v-autocomplete>

    <v-autocomplete v-model="userSettings.firstDay" :items="daysOfWeek" :label="$t('settings-page.first-day-of-week')"
                    item-text="day" item-value="val" v-on:change="collectArgs()">
    </v-autocomplete>

    <v-autocomplete v-model="userSettings.calendarInitialView" :items="calendarInitialViews"
                    :label="$t('settings-page.default-view')"
                    item-text="viewName" item-value="apiName" v-on:change="collectArgs()">
    </v-autocomplete>

    <v-autocomplete v-model="userSettings.calendarLocale" :items="locales" :label="$t('settings-page.locale')"
                    item-text="loc" item-value="val" v-on:change="collectArgs()">
    </v-autocomplete>

    <v-autocomplete v-model="userSettings.slotDuration" :items="durations" :label="$t('settings-page.slot-duration')"
                    item-text="duration" item-value="val" v-on:change="collectArgs()">
    </v-autocomplete>

    <v-autocomplete v-model="userSettings.slotLabelInterval" :items="intervals"
                    :label="$t('settings-page.slot-label-interval')"
                    item-text="int" item-value="val" v-on:change="collectArgs()">
    </v-autocomplete>

    <v-subheader>{{$t('settings-page.business-hours')}}</v-subheader>
    <v-range-slider
      v-on:change="collectArgs()"
      color="indigo"
      v-model="busHrsRange"
      :value="[0, 1]"
      :max=max
      :min=min
      :tick-labels="hours"
      tick-size="2"
      class="align-center">
      <template v-slot:thumb-label="props">
        <v-icon dark>
          {{ props.value }}
        </v-icon>
      </template>
    </v-range-slider>

    <v-subheader>{{$t('settings-page.visible-hours')}}</v-subheader>
    <v-range-slider
      v-on:change="collectArgs()"
      color="red"
      v-model="visibleRange"
      :value="[0, 1]"
      :max=max
      :min=min
      :tick-labels="hours"
      tick-size="2"
      class="align-center">
      <template v-slot:thumb-label="props">
        <v-icon dark>
          {{ props.value }}
        </v-icon>
      </template>
    </v-range-slider>
    <v-btn v-on:click="$emit('update-settings',args)" color="primary">{{$t('buttons.save')}}</v-btn>
  </v-container>
</template>

<script>
  export default {
    name: "CalendarSettingsTab",
    props: ['userSettings'],
    data() {
      return {
        min: 0,
        max: 24,
        selectedDays: [],
        busHrsRange: [],
        visibleRange: [],
        args: [],
        hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24],
        durations: [
          {id: 1, val: '00:30', duration: this.$t('durations.30min')},
          {id: 2, val: '01:00', duration: this.$t('durations.1hr')},
          {id: 3, val: '02:00', duration: this.$t('durations.2hr')},
          {id: 4, val: '03:00', duration: this.$t('durations.3hr')}
        ],
        intervals: [
          {id: 1, val: '00:30', int: this.$t('durations.30min')},
          {id: 2, val: '01:00', int: this.$t('durations.1hr')},
          {id: 3, val: '01:30', int: this.$t('durations.1hr30min')},
          {id: 4, val: '02:00', int: this.$t('durations.2hr')},
          {id: 5, val: '02:30', int: this.$t('durations.2hr30min')},
          {id: 6, val: '03:00', int: this.$t('durations.3hr')}
        ],
        locales: [
          {id: 1, val: 'uk', loc: this.$t('locales.ua')},
          {id: 2, val: 'en-gb', loc: this.$t('locales.en')},
          {id: 3, val: 'fr', loc: this.$t('locales.fr')},
          {id: 4, val: 'de', loc: this.$t('locales.de')},
          {id: 5, val: 'es', loc: this.$t('locales.es')},
          {id: 6, val: 'zh-cn', loc: this.$t('locales.zh-cn')}
        ],
        daysOfWeek: [
          {id: 1, val: 1, day: this.$t('weekdays.monday')},
          {id: 2, val: 2, day: this.$t('weekdays.tuesday')},
          {id: 3, val: 3, day: this.$t('weekdays.wednesday')},
          {id: 4, val: 4, day: this.$t('weekdays.thursday')},
          {id: 5, val: 5, day: this.$t('weekdays.friday')},
          {id: 6, val: 6, day: this.$t('weekdays.saturday')},
          {id: 7, val: 7, day: this.$t('weekdays.sunday')}
        ],
        calendarInitialViews: [
          {id: 1, apiName: 'dayGridMonth', viewName: this.$t('calendar-views.month')},
          {id: 2, apiName: 'dayGridDay', viewName: this.$t('calendar-views.day')},
          {id: 3, apiName: 'dayGridWeek', viewName: this.$t('calendar-views.week-day')},
          {id: 4, apiName: 'timeGridWeek', viewName: this.$t('calendar-views.week-time')},
          {id: 5, apiName: 'timeGridDay', viewName: this.$t('calendar-views.day-time')},
          {id: 6, apiName: 'listWeek', viewName: this.$t('calendar-views.list-week')},
          {id: 7, apiName: 'listDay', viewName: this.$t('calendar-views.list-day')}
        ]
      }
    },
    methods: {
      collectArgs() {
        this.userSettings.daysOfWeek = this.selectedDays.toString();
        this.userSettings.busHoursStart = this.busHrsRange[0];
        this.userSettings.busHoursEnd = this.busHrsRange[1];
        this.userSettings.slotMinTime = this.visibleRange[0];
        this.userSettings.slotMaxTime = this.visibleRange[1];
        this.args = [this.userSettings, 'cal']
      },
      getBusHoursRange() {
        return this.busHrsRange = [this.userSettings.busHoursStart, this.userSettings.busHoursEnd]
      },
      getVisibleRange() {
        return this.visibleRange = [this.userSettings.slotMinTime, this.userSettings.slotMaxTime]
      },
      getSelectedDays() {
        this.selectedDays = this.userSettings.daysOfWeek.split(',').map(Number);
      }
    },
    created() {
      this.getBusHoursRange()
      this.getVisibleRange()
      this.getSelectedDays()
    }
  }
</script>

<style scoped>

</style>
