<template>
  <v-container>
    <v-row justify="space-around">
      <v-row class="ma-2" style="flex: 0 0 auto">
        <v-col md="6">
          <v-color-picker :mode.sync="mode"></v-color-picker>
        </v-col>
        <v-col md="6">
          <v-select v-model="mode" :items="modes" style="max-width: 300px"></v-select>
        </v-col>
      </v-row>
    </v-row>
    <v-btn v-on:click="$emit('update-settings',args)" color="primary">Save</v-btn>
  </v-container>
</template>

<script>
  export default {
    name: "ColorSettingsTab",
    props: ['userSettings'],
    data: () => ({
      mode: 'rgba',
      modes: ['hsla', 'rgba', 'hexa'],
      args: [],
      languages: [
        {id: 1, val: 'en', lang: 'English [EN]'},
        {id: 2, val: 'uk', lang: 'Ukrainian [UA]'},
        {id: 3, val: 'de', lang: 'German [DE]'},
        {id: 3, val: 'fr', lang: 'French [FR]'},
        {id: 5, val: 'es', lang: 'Spanish [ES]'},
        {id: 6, val: 'cn', lang: 'Chinese Simplified [CN]'}
      ]
    }),
    methods: {
      collectArgs() {
        this.args = [this.userSettings, 'color']
      }
    }
  }
</script>

<style scoped>

</style>
