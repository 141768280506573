<template>
  <v-container>
    <v-row v-if="userSettings">
      <v-tabs grow show-arrows continuous slider-size="4" centered icons-and-text>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in tabs"
          :key="tab.tabNr">
          {{ tab.tabTitle }}
          <v-icon :color="tab.iconColor">{{ tab.tabIcon }}</v-icon>
        </v-tab>
        <v-tab-item>
          <GlobalSettingsTab :userSettings="userSettings"
                             v-on:update-settings="updateSettings($event)"></GlobalSettingsTab>
        </v-tab-item>
        <v-tab-item>
          <CalendarSettingsTab :userSettings="userSettings"
                               v-on:update-settings="updateSettings($event)"></CalendarSettingsTab>
        </v-tab-item>
        <v-tab-item>
          <OrderSettingsTab :userSettings="userSettings"
                            v-on:update-settings="updateSettings($event)"></OrderSettingsTab>
        </v-tab-item>

        <v-tab-item>
          <ColorSettingsTab :userSettings="userSettings"
                            v-on:update-settings="updateSettings($event)"></ColorSettingsTab>
        </v-tab-item>
        <v-tab-item v-for="tab in tabs" :key="tab.tabNr">
        </v-tab-item>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
  import {AXIOS} from "../../../http-commons";
  import {mapState} from "pinia";
  import {useUser} from "../../stores/userStore";
  import GlobalSettingsTab from "./setting-tabs/GlobalSettingsTab";
  import CalendarSettingsTab from "./setting-tabs/CalendarSettingsTab";
  import OrderSettingsTab from "./setting-tabs/OrderSettingsTab";
  import ColorSettingsTab from "./setting-tabs/ColorSettingsTab";

  const userStore = useUser()

  export default {
    name: "Settings",
    components: {OrderSettingsTab, CalendarSettingsTab, GlobalSettingsTab, ColorSettingsTab},
    data() {
      return {
        tabs: [{
          loading: false,
          tabNr: 0,
          tabTitle: this.$t('settings-tabs.global-settings'),
          tabIcon: "mdi-earth",
          iconColor: "red"
        },
          {
            tabNr: 1,
            tabTitle: this.$t('settings-tabs.calendar-settings'),
            tabIcon: "mdi-calendar-multiple",
            iconColor: "indigo"
          },
          {
            tabNr: 2,
            tabTitle: this.$t('settings-tabs.order-settings'),
            tabIcon: "mdi-clipboard-list-outline",
            iconColor: "amber"
          },
          {
            tabNr: 3,
            tabTitle: this.$t('settings-tabs.color-settings'),
            tabIcon: "mdi-head-lightbulb-outline",
            iconColor: "green"
          },
        ],
      }
    },
    methods: {
      updateSettings(args) {
        let setting = args[0];
        let type = args[1];
        let action = 'update';
        let params = new URLSearchParams();
        let objectType = 'User settings'
        params.append('userId', this.currentUser.id);
        params.append('type', args[1]);
        //GLOBAL settings
        if (type === 'global') {
          params.append('uiLanguage', setting);
          console.log("param", setting)
        }
        //ORDER settings
        if (type === 'order') {
          params.append('defaultOrderType', setting.defaultOrderType);
          params.append('minOrderEnabled', setting.minOrderEnabled);
          console.log("param", setting)
        }
        //CALENDAR settings
        if (type === 'cal') {
          params.append('busHoursStart', setting.busHoursStart);
          params.append('busHoursEnd', setting.busHoursEnd);
          params.append('calendarInitialView', setting.calendarInitialView);
          params.append('calendarLocale', setting.calendarLocale);
          params.append('daysOfWeek', setting.daysOfWeek);
          params.append('firstDay', setting.firstDay);
          params.append('slotMinTime', setting.slotMinTime);
          params.append('slotMaxTime', setting.slotMaxTime);
          params.append('slotDuration', setting.slotDuration);
          params.append('slotLabelInterval', setting.slotLabelInterval);
          params.append('showWeekends', setting.showWeekends);
          console.log("param", setting)
        }
        AXIOS.put(`/api/updateUserSettings`, params)
          .then(response => {
            userStore.patchSettings(response.data)
            this.userNotification(response.data, action, objectType);
            this.$i18n.locale = response.data.uiLanguage;
          })
      },
    },
    computed: {
      ...mapState(useUser, ['userSettings'])
    },
  }
</script>
